import styled from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const CharacterTasteCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 503px;
  height: 500px;
  padding: 0 32px 32px;
  overflow: hidden;
  border-radius: 60px 10px 0 0;
  box-shadow: ${STATIC_SHADOWS.lg};

  button {
    z-index: 3;
    margin-top: 24px;
    background-color: ${STATIC_COLORS.base.white} !important;
  }
`;

export const CharacterBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  min-width: 503px;
  max-width: 503px;
  min-height: 838px;
  max-height: 838px;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.3;
`;

export const CharacterCardContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 2;
  margin-top: auto;
  gap: 12px;
  color: ${STATIC_COLORS.base.white};
`;

export const TestAgainTest = styled(Text)`
  display: block;
  margin-top: 12px;

  .cta-test {
    margin-bottom: 0;
    color: ${STATIC_COLORS.base.white};

    .decorated-text {
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const MissedCharacterImage = styled.img`
  display: block;
  height: 156px;
  margin: 0 auto 12px;
`;

export const MissedCharacterTitle = styled(Text)`
  margin-bottom: 12px;
`;
