import { FC } from 'react';

import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';

import percentageIcon from '@components/web/src/assets/legacy/b2b/kiosk/icons/percentage_icon.svg';
import arrowIcon from '@components/web/src/assets/legacy/b2b/kiosk/icons/right_arrow_icon.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/kiosk/Cards/PromotionCard/styles';

interface IProps {
  handleClick: () => void;
}

export const PromotionCard: FC<IProps> = ({ handleClick }) => {
  const { promotionCard } = localeKiosk.homePageCards;

  return (
    <S.PromotionCardContainer role="button" tabIndex={-1} onClick={handleClick} onKeyDown={handleClick}>
      <Image alt="percentage icon" height="24px" maxWidth="24px" minWidth="24px" src={percentageIcon} />
      <Text color={STATIC_COLORS.teal[600]} size="subtitle1" text={promotionCard.title} weight="bold" />
      <Image alt="arrow icon" height="20px" maxWidth="20px" minWidth="20px" src={arrowIcon} />
    </S.PromotionCardContainer>
  );
};
