import { styled } from 'styled-components';

import { STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { skeletonLoadingAnimation, skeletonLoadingKeyframes } from '@components/web/src/organisms/Skeleton/styles';

export const SkeletonResultPageContainer = styled(Flexbox)`
  overflow: hidden;
`;

export const SkeletonSituationalPageHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 124px;
  padding: 20px 140px 0 40px;
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  box-shadow: ${STATIC_SHADOWS.lg};
  animation: ${skeletonLoadingKeyframes} ${skeletonLoadingAnimation};
`;

export const SkeletonCharacterDetail = styled(Flexbox)`
  width: 1080px;
  height: fit-content;
  background: ${STATIC_GRADIENTS.warmGradientReversed};
  box-shadow: ${STATIC_SHADOWS.lg};
  animation: ${skeletonLoadingKeyframes} ${skeletonLoadingAnimation};
`;

export const SkeletonCharacterDetailLeft = styled(Flexbox)`
  width: 400px;
  height: 440px;
`;

export const SkeletonCharacterDetailLeftRectangleOne = styled.div`
  width: 220px;
  height: 400px;
  margin-bottom: 0;
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  border-radius: 10px;
`;

export const SkeletonCharacterDetailRight = styled.div`
  width: 500px;
`;

export const SkeletonCharacterDetailRightRectangleBarOne = styled.div`
  width: 400px;
  height: 20px;
  margin-bottom: 4px;
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  border-radius: 10px;
`;

export const SkeletonCharacterDetailRightInputBar = styled.div`
  width: 400px;
  height: 60px;
  margin-bottom: 4px;
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  border-radius: 10px;
`;

export const SkeletonCharacterDetailRightRectangleBarTwo = styled.div`
  width: 400px;
  height: 20px;
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  border-radius: 10px;
`;

export const SkeletonCharacterDetailRightCrossSection = styled.div`
  width: 400px;
  height: 100px;
  margin-top: 24px;
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  border-radius: 10px;
`;

export const SkeletonBodyWrapper = styled(Flexbox)`
  overflow: hidden;

  :nth-child(1) {
    margin-bottom: 20px;
  }
`;

export const SkeletonTitlePage = styled.div`
  width: 100%;
  height: 34px;
  padding: 0 32px;
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  border-radius: 10px;
`;

export const SkeletonBody = styled.div`
  position: relative;
  width: 100%;
  height: 460px;

  :nth-child(1) {
    position: absolute;
    right: 0;
    left: 0;
  }

  :nth-child(2) {
    position: absolute;
    left: 340px;
  }

  :nth-child(3) {
    position: absolute;
    left: 680px;
    display: block;
  }
`;

export const ProductCardSkeleton = styled.div`
  width: 336px;
  height: 460px;
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  border-radius: 10px;
  animation: ${skeletonLoadingKeyframes} ${skeletonLoadingAnimation};
`;

export const ButtonSkeleton = styled.div`
  width: 230px;
  height: 52px;
  margin-bottom: 724px;
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  border-radius: 10px;
  animation: ${skeletonLoadingKeyframes} ${skeletonLoadingAnimation};
`;
