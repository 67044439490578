import styled from 'styled-components';

export const UserTasteCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 1016px;
`;

export const PropositionCardsWrapper = styled.div`
  display: flex;
  gap: 10px;
  height: auto;
`;
