import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { getProductCategoryPairingIcon } from '@lib/tools/shared/helpers';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/kiosk/Cards/HomeRecipeCard/styles';

export type Props = {
  productCategory: TProductCategory;
  primaryProductColor?: string;
  handleClick: () => void;
  customBorder?: string;
};

export const HomeRecipeCard: FC<Props> = ({ productCategory, primaryProductColor, handleClick, customBorder }) => {
  const { recipeCard } = localeKiosk.homePageCards;

  return (
    <S.HomeRecipeCardContainer style={{ borderRadius: customBorder }}>
      <S.RecipeContainer>
        <S.RecipeContent>
          <Text color={STATIC_COLORS.gray[900]} size="h5" text={recipeCard.description} weight="medium" />
          <Image alt="recipe" height="200px" src={getProductCategoryPairingIcon(productCategory)} width="200px" />
        </S.RecipeContent>
        <Button
          fontSize="subtitle1"
          handleClick={handleClick}
          size="lg"
          text={recipeCard.btn}
          textWeight="medium"
          variant={VH_VARIANTS.PRIMARY}
          style={{
            backgroundColor: primaryProductColor,
          }}
        />
      </S.RecipeContainer>
    </S.HomeRecipeCardContainer>
  );
};
