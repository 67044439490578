import styled from 'styled-components';

import { ReactComponent as BackgroundImage } from '@components/web/src/assets/icons/welcome/kiosk_welcome_bg.svg';
import { SCREEN_SIZE, STATIC_COLORS } from '@components/web/src/foundations';

export const WelcomePageContainer = styled.div`
  position: relative;
  width: ${SCREEN_SIZE.desktopKiosk};
`;

export const MediaSectionWrapper = styled.div`
  width: ${SCREEN_SIZE.desktopKiosk};
  height: 1080px;
  background: ${STATIC_COLORS.base.white};
`;

export const BottomSectionWrapper = styled.div`
  position: absolute;
  top: 990px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${SCREEN_SIZE.desktopKiosk};
  height: 930px;
`;

export const Pattern = styled.div<{ $backgroundImage: string }>`
  position: absolute;
  z-index: 2;
  inset: 18px 0 0 0;
  background: ${({ $backgroundImage }) => `url(${$backgroundImage})`};
`;

export const Background = styled(BackgroundImage)`
  path {
    fill: var(--color-primary-700) !important;
  }
`;

export const TextWrapper = styled.div`
  position: absolute;
  z-index: 3;
  top: 180px;
  width: ${SCREEN_SIZE.desktopKiosk};
  text-align: center;
`;

export const StartButton = styled.button`
  all: unset;
  position: absolute;
  z-index: 3;
  top: 343px;
  width: 290px;
  height: 290px;
  border-radius: 50%;
  background: var(--color-primary-700);

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 220px;
    height: 220px;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-primary-800);
  }
`;

export const Badge = styled.div`
  position: absolute;
  z-index: 3;
  top: 745px;
  min-width: 220px;
  padding: 13px 10px;
  background: var(--color-primary-800);
  border-radius: 20px;
  text-align: center;
`;
