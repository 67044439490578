import { FC } from 'react';

import { TEssence } from '@lib/core/characters/types/essences';
import { TProductCategory } from '@lib/core/products/types';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { TOTAL_INDICATOR_AMOUNT } from '@components/web/src/foundations/consts';
import * as S from '@components/web/src/organisms/KioskCharacterDetails/ResultEssenceItem/styles';
import ProgressBar, { IProgressBarVariant } from '@components/web/src/organisms/ProgressBar/ProgressBar';

interface Props extends TEssence {
  variant?: TProductCategory;
}
const ResultEssenceItem: FC<Props> = ({ score, essence: { name }, variant = VH_VARIANTS.LIGHT }) => {
  return (
    <S.ResultEssenceContainer isFullWidth align="center" gap={16} justify="flex-start">
      <S.ResultEssenceTitle color={STATIC_COLORS.base.black} size="body2" text={name} weight="medium" />

      <ProgressBar
        progressPercentage={(score / TOTAL_INDICATOR_AMOUNT) * 100}
        variant={variant as IProgressBarVariant}
      />
    </S.ResultEssenceContainer>
  );
};

export default ResultEssenceItem;
