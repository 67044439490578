import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ResultEssenceItem from '@components/web/src/organisms/KioskCharacterDetails/ResultEssenceItem/ResultEssenceItem';
import * as S from '@components/web/src/organisms/KioskCharacterDetails/styles';

export const KioskCharacterDetails: FC<IParsedCharacter> = ({
  characterProductCategory,
  characterImage,
  characterName,
  characterCaption,
  characterRelatedEssencesSorted,
  characterDescription,
}) => {
  const { characterTitle } = localeKiosk.characterDetails;
  return (
    <S.CharacterDetailsContainer align="flex-start" justify="center">
      <S.CharacterImage $characterImage={characterImage} />
      <S.CharacterSummary direction="column">
        <S.CharacterTitle color={STATIC_COLORS.base.black} size="body1" text={characterTitle} />
        <S.CharacterName
          color={STATIC_COLORS.productColors[characterProductCategory?.name][500]}
          fontFamily="Fraunces"
          size="h4"
          text={`#${characterName}`}
          weight="semibold"
        />
        <Text color={STATIC_COLORS.base.black} size="subtitle1" text={characterCaption} weight="medium" />

        <S.CharacterEssenceGroup align="flex-start" direction="column" gap={8}>
          {characterRelatedEssencesSorted.map(essenceObj => (
            <ResultEssenceItem
              key={essenceObj.essence.identifier}
              {...essenceObj}
              variant={characterProductCategory?.name}
            />
          ))}
        </S.CharacterEssenceGroup>
        <Text color={STATIC_COLORS.base.black} size="body2" text={characterDescription} />
      </S.CharacterSummary>
    </S.CharacterDetailsContainer>
  );
};

export default KioskCharacterDetails;
