import styled from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const PromotionCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 100px;
  padding: 24px 32px;
  background: ${STATIC_COLORS.base.white};
  border-radius: 0 0 60px 60px;
  box-shadow: ${STATIC_SHADOWS.lg};

  span {
    width: 100%;
  }
`;
