import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { PRODUCT_CATEGORY_WINE } from '@lib/tools/shared/helpers/consts';

import buttonIcon from '@components/web/src/assets/icons/welcome/button_icon.png';
import pattern from '@components/web/src/assets/icons/welcome/kiosk_welcome_pattern.png';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import VideoPlaylist from '@components/web/src/organisms/VideoPlaylist/VideoPlaylist';
import * as S from '@components/web/src/templates/Start&HomePages/Kiosk/WelcomePage/styles';

interface IProps {
  mediaList: string | string[];
  productCategory: TProductCategory;
  handleStartButtonClick: () => void;
}

export const WelcomePage: FC<IProps> = ({
  mediaList,
  productCategory = PRODUCT_CATEGORY_WINE,
  handleStartButtonClick,
}) => {
  const { pageInfo, pageTitle, ctaBtn } = localeKiosk.welcomePageText;
  const { productCategories } = localeCommon;

  return (
    <S.WelcomePageContainer>
      <S.MediaSectionWrapper>
        <VideoPlaylist videos={mediaList} />
      </S.MediaSectionWrapper>
      <S.BottomSectionWrapper>
        <S.Background />
        <S.Pattern $backgroundImage={pattern} />
        <S.TextWrapper>
          <Text
            color={STATIC_COLORS.base.white}
            fontFamily="Fraunces"
            localeVariables={{ productCategoryText: productCategories[productCategory] }}
            size="h3"
            text={pageTitle}
            textAlign="center"
            weight="semibold"
            localeOptions={{
              productCategory,
            }}
          />
        </S.TextWrapper>
        <S.StartButton onClick={handleStartButtonClick}>
          <img alt="start button icon" src={buttonIcon} />
          <Text color={STATIC_COLORS.base.white} size="h3" text={ctaBtn} weight="medium" />
        </S.StartButton>
        <S.Badge>
          <Text color={STATIC_COLORS.base.white} size="body1" text={pageInfo} />
        </S.Badge>
      </S.BottomSectionWrapper>
    </S.WelcomePageContainer>
  );
};
