import styled from 'styled-components';

import { SCREEN_SIZE, STATIC_COLORS } from '@components/web/src/foundations';

export const KioskPageContainer = styled.div`
  position: relative;
  height: 100%;
  width: ${SCREEN_SIZE.desktopKiosk};
  margin: 0 auto;
  background-color: ${STATIC_COLORS.warmGray[100]};
`;
