import styled from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const HomeRecipeCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 503px;
  padding: 24px 32px 32px;
  background: ${STATIC_COLORS.base.white};
  border-radius: 0 60px 60px 0;
  box-shadow: ${STATIC_SHADOWS.lg};
`;

export const RecipeContainer = styled.div`
  margin-top: auto;

  button {
    width: 100%;
  }
`;

export const RecipeContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 24px;
  align-items: center;
`;
