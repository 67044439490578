import { useNavigate } from 'react-router-dom';

import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { ENV_MEDIA_DOMAIN } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { PAGES } from '@lib/tools/views/urls';

import { WelcomePage } from '@components/web/src/templates/Start&HomePages/Kiosk/WelcomePage/WelcomePage';

export const WelcomePageContainer = () => {
  const navigate = useNavigate();

  const { productCategory, locale } = useApp();
  const { customStyle } = useRetailerLocation();
  const retailerMediaAvailable: string[] = customStyle[productCategory]?.welcome || [];

  const handleStartButtonClick = () => {
    MixpanelTracker.events.startButtonClick();
    navigate(prependBasename(PAGES.vinhood.home));
  };

  const fallbackMedias = {
    beer: [`${ENV_MEDIA_DOMAIN}/media/kiosk_custom_backgrounds/${locale}-beer-kiosk.mp4`],
    coffee: [`${ENV_MEDIA_DOMAIN}/media/kiosk_custom_backgrounds/${locale}-coffee-kiosk.mp4`],
    wine: [`${ENV_MEDIA_DOMAIN}/media/kiosk_custom_backgrounds/${locale}-wine-kiosk.mp4`],
  };

  const media =
    retailerMediaAvailable && retailerMediaAvailable[locale] && retailerMediaAvailable[locale].length > 0
      ? retailerMediaAvailable[locale]
      : fallbackMedias[productCategory];

  return (
    <WelcomePage handleStartButtonClick={handleStartButtonClick} mediaList={media} productCategory={productCategory} />
  );
};
