import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { getPrimaryProductCategoryColor } from '@lib/tools/shared/helpers';

import { CharacterTasteCard } from '@components/web/src/kiosk/Cards/CharacterTasteCard/CharacterTasteCard';
import { HomeRecipeCard } from '@components/web/src/kiosk/Cards/HomeRecipeCard/HomeRecipeCard';
import { PromotionCard } from '@components/web/src/kiosk/Cards/PromotionCard/PromotionCard';
import * as S from '@components/web/src/kiosk/Cards/UserTasteCards/styles';
import {
  PROPOSITION_CARD_VARIANTS,
  PropositionCard,
} from '@components/web/src/organisms/Cards/PropositionCard/PropositionCard';
import { KioskHomeSkeleton } from '@components/web/src/organisms/Skeleton/KioskHomeSkeleton/KioskHomeSkeleton';

interface IProps {
  productCategory: TProductCategory;
  tipParsedProductInstanceData: TParsedProductInstance;
  wishlistParsedProductInstanceData: TParsedProductInstance;
  wishlistQuantity: number;
  userBestMatchCharacterForCurrentServiceProductCategory?: IParsedCharacter;
  isPromotionProductsAvailable: boolean;
  isLoading: boolean;
  onPromotionCardClick: () => void;
  navigateToProductPage: (productId: string) => void;
  navigateToTasteTest: () => void;
  navigateToProductCatalogPage: (isEnableWishlistFilter?: boolean) => void;
  navigateToSituationalTest: () => void;
}

export const UserTasteCards: FC<IProps> = ({
  productCategory,
  tipParsedProductInstanceData,
  wishlistParsedProductInstanceData,
  wishlistQuantity,
  userBestMatchCharacterForCurrentServiceProductCategory,
  isPromotionProductsAvailable = false,
  isLoading,
  onPromotionCardClick,
  navigateToProductPage,
  navigateToTasteTest,
  navigateToProductCatalogPage,
  navigateToSituationalTest,
}) => {
  const primaryProductColor = getPrimaryProductCategoryColor(productCategory);

  return (
    <>
      {isLoading ? (
        <KioskHomeSkeleton />
      ) : (
        <S.UserTasteCardContainer>
          <CharacterTasteCard
            characterData={userBestMatchCharacterForCurrentServiceProductCategory}
            navigateToProductCatalogPage={navigateToProductCatalogPage}
            navigateToTasteTest={navigateToTasteTest}
            productCategory={productCategory}
          />
          <HomeRecipeCard
            customBorder="10px 60px 0 0"
            handleClick={navigateToSituationalTest}
            primaryProductColor={primaryProductColor}
            productCategory={productCategory}
          />
          <S.PropositionCardsWrapper>
            <PropositionCard
              key={PROPOSITION_CARD_VARIANTS.tip}
              isNotRoundedVariant={isPromotionProductsAvailable}
              navigateToProductPage={navigateToProductPage}
              navigateToTasteTest={navigateToTasteTest}
              parsedProductInstanceData={tipParsedProductInstanceData}
              productCategory={productCategory}
              variant={PROPOSITION_CARD_VARIANTS.tip}
            />
            <PropositionCard
              key={PROPOSITION_CARD_VARIANTS.wishlist}
              isNotRoundedVariant={isPromotionProductsAvailable}
              navigateToProductCatalogPage={navigateToProductCatalogPage}
              navigateToProductPage={navigateToProductPage}
              navigateToTasteTest={navigateToTasteTest}
              parsedProductInstanceData={wishlistParsedProductInstanceData}
              productCategory={productCategory}
              variant={PROPOSITION_CARD_VARIANTS.wishlist}
              wishlistQuantity={wishlistQuantity}
            />
          </S.PropositionCardsWrapper>
          {isPromotionProductsAvailable && <PromotionCard handleClick={onPromotionCardClick} />}
        </S.UserTasteCardContainer>
      )}
    </>
  );
};
