import { FC } from 'react';

import { KioskContainer } from '@components/web/src/kiosk/KioskContainer/KioskContainer';
import * as S from '@components/web/src/organisms/Skeleton/ResultPageSkeleton/style';

type IResultPageSkeletonProps = {
  isFromSituationTest: boolean;
};

export const ResultPageSkeleton: FC<IResultPageSkeletonProps> = ({ isFromSituationTest }) => {
  return (
    <KioskContainer>
      <S.SkeletonResultPageContainer isFullWidth align="center" direction="column" gap={0} justify="center">
        {isFromSituationTest ? (
          <S.SkeletonSituationalPageHeader />
        ) : (
          <S.SkeletonCharacterDetail align="center" gap={0} justify="center" padding="20px 40px 0 140px">
            <S.SkeletonCharacterDetailLeft align="center" gap={0} justify="flex-start">
              <S.SkeletonCharacterDetailLeftRectangleOne />
            </S.SkeletonCharacterDetailLeft>
            <S.SkeletonCharacterDetailRight>
              <S.SkeletonCharacterDetailRightRectangleBarOne />
              <S.SkeletonCharacterDetailRightInputBar />
              <S.SkeletonCharacterDetailRightRectangleBarTwo />
              <S.SkeletonCharacterDetailRightCrossSection />
              <S.SkeletonCharacterDetailRightCrossSection />
            </S.SkeletonCharacterDetailRight>
          </S.SkeletonCharacterDetail>
        )}
        <S.SkeletonBodyWrapper
          isFullWidth
          align="center"
          direction="column"
          gap={0}
          justify="center"
          padding="80px 32px 30px"
        >
          <S.SkeletonTitlePage />
          <S.SkeletonBody>
            <S.ProductCardSkeleton />
            <S.ProductCardSkeleton />
            <S.ProductCardSkeleton />
          </S.SkeletonBody>
        </S.SkeletonBodyWrapper>
        <S.ButtonSkeleton />
      </S.SkeletonResultPageContainer>
    </KioskContainer>
  );
};
