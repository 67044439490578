import { styled } from 'styled-components';

import { STATIC_GRADIENTS } from '@components/web/src/foundations';
import { skeletonLoadingAnimation, skeletonLoadingKeyframes } from '@components/web/src/organisms/Skeleton/styles';

export const SkeletonKioskHomeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 1016px;
`;

export const SkeletonKioskHomeContainerSquareTile = styled.div`
  animation: ${skeletonLoadingKeyframes} ${skeletonLoadingAnimation};

  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
`;
