import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';

import * as S from '@components/web/src/kiosk/GroupedProducts/styles';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/ProductCard';
import SwiperHOC from '@components/web/src/organisms/Swiper/SwiperHOC';

export const GroupedProducts = ({
  products = [],
  isMobileResolution,
  isProductsLoading,
  storeType,
  shouldHideRatingButton,
  shouldHideWishlist,
  shouldHideShare,
}) => (
  <S.GroupedProductsWrapper>
    {isMobileResolution ? (
      <SwiperHOC showPaginationDots>
        {isProductsLoading
          ? Array.from({ length: 3 }, (_, i) => (
              <ProductCard key={i + 1} isLoading={isProductsLoading} storeType={storeType} />
            ))
          : products?.map((parsedProductInstanceData, i) => (
              <ProductCard
                key={i}
                isResponsive
                isLoading={isProductsLoading}
                mixpanelIndex={i}
                mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
                parsedProductInstanceData={parsedProductInstanceData}
                shouldHideRatingButton={shouldHideRatingButton}
                shouldHideShare={shouldHideShare}
                shouldHideWishlist={shouldHideWishlist}
                storeType={storeType}
              />
            ))}
      </SwiperHOC>
    ) : (
      <>
        {isProductsLoading
          ? Array.from({ length: 3 }, (_, i) => (
              <ProductCard key={i + 1} isLoading={isProductsLoading} storeType={storeType} />
            ))
          : products.map((parsedProductInstanceData, i) => (
              <ProductCard
                key={i}
                isLoading={isProductsLoading}
                mixpanelIndex={i}
                mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
                parsedProductInstanceData={parsedProductInstanceData}
                shouldHideRatingButton={shouldHideRatingButton}
                shouldHideShare={shouldHideShare}
                shouldHideWishlist={shouldHideWishlist}
                storeType={storeType}
              />
            ))}
      </>
    )}
  </S.GroupedProductsWrapper>
);
