import styled from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const TasteTestCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 3;
`;

export const TasteCardContainer = styled(Flexbox)`
  width: 503px;
  background: ${STATIC_COLORS.base.white};
  border-radius: 60px 0 0 60px;
  box-shadow: ${STATIC_SHADOWS.lg};
`;
