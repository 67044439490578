import * as S from '@components/web/src/organisms/Skeleton/KioskHomeSkeleton/styles';

export const KioskHomeSkeleton = () => {
  const cardStyles = [
    { borderRadius: ' 40px 10px 0px 0px', height: '500px', width: '503px' },
    { borderRadius: '10px 40px 0px 0px', height: '500px', width: '503px' },
    { borderRadius: '0px 0px 10px 40px', height: '542px', width: '503px' },
    { borderRadius: '0px 0px 40px 10px', height: '542px', width: '503px' },
  ];

  return (
    <S.SkeletonKioskHomeContainer>
      {cardStyles.map((style, index) => (
        <S.SkeletonKioskHomeContainerSquareTile key={index} style={style} />
      ))}
    </S.SkeletonKioskHomeContainer>
  );
};
