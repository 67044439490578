import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { IGroupedProduct } from '@app/kiosk/src/containers/SituationalResultContainer';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { LocaleUtils } from '@lib/tools/locale/utils';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import Button from '@components/web/src/atoms/Buttons/Button';
import { B2B_MOBILE_RESOLUTION_MAX_VALUE } from '@components/web/src/kiosk/consts';
import { GroupedProducts } from '@components/web/src/kiosk/GroupedProducts/GroupedProducts';
import { KioskCirclesBackground } from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import { KioskContainer } from '@components/web/src/kiosk/KioskContainer/KioskContainer';
import NoProductsAvailable from '@components/web/src/organisms/Catalog/NoProductsAvailable/NoProductsAvailable';

export type Props = {
  productCategory: TProductCategory;
  isProductsLoading: boolean;
  locale: string;
  groupedProducts: IGroupedProduct[];
  storeType: TRetailerLocationStoreType;
  charactersData: IParsedCharacter[];
  shouldHideRatingButton?: boolean;
  shouldHideShare?: boolean;
  shouldHideWishlist?: boolean;
  navigateToProductCatalogPage: (characterId: string) => void;
};

export const SituationalResultPage: FC<Props> = ({
  groupedProducts,
  productCategory,
  isProductsLoading,
  locale,
  storeType,
  charactersData,
  shouldHideRatingButton,
  shouldHideWishlist,
  navigateToProductCatalogPage,
  shouldHideShare,
}) => {
  const isMobileResolution = useMediaQuery({ maxWidth: B2B_MOBILE_RESOLUTION_MAX_VALUE });

  const { resultPage } = localeKiosk;
  const { productCategoriesPlural } = localeCommon;

  const { publishedTerms } = LocaleUtils;

  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory]?.id];
  const { SituationalResultPageText } = localeKiosk;

  return (
    <KioskContainer>
      <div className="situational-result-page-container">
        <KioskCirclesBackground />
        <div className="situational-result-page-header">
          <p className="situational-result-page-title-text">
            <LocaleFragment message={SituationalResultPageText.SituationalPageTitleText} />
          </p>
          <p className={`situational-result-page-header-text ${productCategory}`}>
            <LocaleFragment message={SituationalResultPageText.SituationalPageHeaderText} />
          </p>
        </div>
        {groupedProducts.length > 0 ? (
          <>
            {groupedProducts.map(({ characterId, products }, index) => {
              const characterObj = charactersData.find(obj => obj.characterId === characterId);
              const titleText = characterObj?.characterAttributes?.[`kiosk_best_choice_label_${locale}`] || '';
              const characterName = characterObj?.characterName;

              return (
                <div key={index}>
                  <p className="grouped-products-title">{titleText}</p>
                  <GroupedProducts
                    isMobileResolution={isMobileResolution}
                    isProductsLoading={isProductsLoading}
                    products={products}
                    shouldHideRatingButton={shouldHideRatingButton}
                    shouldHideShare={shouldHideShare}
                    shouldHideWishlist={shouldHideWishlist}
                    storeType={storeType}
                  />
                  <Button
                    className="situational-result-page-btn"
                    size="lg"
                    text={resultPage.btn}
                    handleClick={() => {
                      MixpanelTracker.events.seeAllProducts();
                      navigateToProductCatalogPage(characterId);
                    }}
                    localeOptions={{
                      characterName,
                      productCategory,
                      productCategoryPluralText,
                    }}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <NoProductsAvailable />
        )}
      </div>
    </KioskContainer>
  );
};
